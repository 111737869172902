import React, { useEffect, useState } from "react";
import "../CSS/common.css";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { baseURL } from "./Apis";
import { RiPencilFill } from "react-icons/ri";
import { FaTimes } from 'react-icons/fa';
import {getUserRoleBasedAccess} from './Apis';
import {decToken} from './Login';

const Usermanagement = () => {
  const userRoleId:any =
    sessionStorage.getItem("userRoleId") === undefined
      ? 0
      : sessionStorage.getItem("userRoleId");
    const user_id:any =
      sessionStorage.getItem("user_id") === undefined
        ? 0
        : sessionStorage.getItem("user_id");
        const org_id:any =
        sessionStorage.getItem("org_id") === undefined
          ? 0
          : sessionStorage.getItem("org_id");
  const authToken =decToken;
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userRoleList, setUserRoleList] = useState([]);
  const [userRoleListTemp, setUserRoleListTemp] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const handleRoleChange = (event: any) => {
    setRoleId(event.target.value);
  };
  const columnDefs:any =   
  userRoleId == 1?
  [
    { name: "User Name", selector: (row: any) => row.fullname, sortable: true },
    {
      name: "Role Name",
      selector: (row: any) => row.role_name,
      sortable: true,
      id: "role_name",
    },
   {
        name: "Organisation Name",
        selector: (row: any) => row.organisation,
        sortable: true,
      }
    ,
    {
      name: "Action",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RiPencilFill
            style={{
              color: "#0072B2",
              cursor: "pointer",
              height: "18px",
              width: "18px",
              marginLeft: "10px",
            }}
            aria-hidden="true"
            onClick={() => {
              setUserName(row.fullname);
              setRoleId(row.role_id);
              setUserId(row.user_id);
              handleShow();
            }}
          />
        </div>
      ),
    },
  ]:
  [
    { name: "User Name", selector: (row: any) => row.fullname, sortable: true },
    {
      name: "Role Name",
      selector: (row: any) => row.role_name,
      sortable: true,
      id: "role_name",
    },
    ,
    {
      name: "Action",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RiPencilFill
            style={{
              color: "#0072B2",
              cursor: "pointer",
              height: "18px",
              width: "18px",
              marginLeft: "10px",
            }}
            aria-hidden="true"
            onClick={() => {
              setUserName(row.fullname);
              setRoleId(row.role_id);
              setUserId(row.user_id);
              handleShow();
            }}
          />
        </div>
      ),
    },
  ];

  const titleCase = (str: any) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const fetchData = async () => {
    setLoader(true);
    var users: any;
    var link:any; 
    if(userRoleId==3)
      link =`${baseURL}/getUsersByOrgId/` + org_id;
    else if (userRoleId==1)
      link = `${baseURL}/users/verified`;
    else
      link = `${baseURL}/getUsersByManager/` + user_id;
    await axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((data) => {
        setUserList(data.data.users);
        setUserRoleListTemp(data.data.users);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching user data in AnotherFile:", error);
        setLoader(false);
      });
    await axios
      .get(`${baseURL}/getRoles`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((data) => {
        var tempArray:any =[];
        data.data.data.map((val:any,key:any)=>{
            if(val.role_id>=+userRoleId)
            {
              tempArray.push(val);
            }
        })
        setRoleList(tempArray);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching user data in AnotherFile:", error);
        setLoader(false);
      });

     
  };

  useEffect(() => {
    fetchData();
  }, []);

  const findRoleDetails = (roleId: any, rolelist: any) => {
    return rolelist.find((role: any) => role.role_id === roleId);
  };

  const updateUserRole = () => {
    setLoader(true);
    var dataToAttach = {
      new_role_id: roleId,
    };
    axios
      .put(`${baseURL}/updateUserRole/` + userId, dataToAttach, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((data) => {
        //console.log(data);
        fetchData();
      })
      .catch((error) =>
        console.error("Error fetching user data in AnotherFile:", error)
      );
    handleClose();
    setLoader(false);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value || "";
    setSearchText(value);
    
    var filteredData:any 
    if(userRoleId==1){
      var noNullData :any = userList.filter((item: any) =>
      item.organisation !== null && item.organisation !=='' 
    );
    console.log(noNullData)
      filteredData = noNullData.filter((item: any) =>
      item.fullname.toLowerCase().includes(value.toLowerCase()) || item.organisation.toLowerCase().includes(value.toLowerCase())
    );
    }else{
    filteredData = userList.filter((item: any) =>
      item.fullname.toLowerCase().includes(value.toLowerCase())
    );
  }
    setUserList(filteredData);
  };

  const handleClear = ()=>{
    setSearchText('');
    setUserList(userRoleListTemp);
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace") {
      setUserList(userRoleListTemp);
    }
  };
  return (
    <>
      {" "}
      {loader ? (
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="titlename">User Management</div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
      <div style={{ position: 'relative', width: '400px' }}>
        <input
          type="text"
          placeholder={userRoleId==1?"Search user by name or organisation name":"Search user by name"}
          value={searchText}
          onChange={handleSearch}
          className="form-control modalbodyinput"
          style={{ width: '100%', paddingRight: '30px' }}
          onKeyDown={handleKeyDown}
        />
        {searchText && (
            <FaTimes style={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
          }} onClick={handleClear} />
          
        )}
      </div>
    </div>
          <DataTable
            title={<div className="managementTableTitle">User List</div>}
            columns={columnDefs}
            data={userList}
            pagination
            fixedHeader
            responsive
            fixedHeaderScrollHeight="700px"
            className="managementTable"
            defaultSortFieldId="role_name"
          />

          <Modal
            className="managementmodal"
            centered
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{fontFamily:'Poppins-Medium'}}>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <label className="modalbodylabel">User Name</label>
                <input
                  className="form-control modalbodyinput"
                  value={userName}
                  disabled
                />
                <label className="modalbodylabel">Select Role</label>
                <select
                  className="form-select modalbodyinput"
                  value={roleId}
                  onChange={handleRoleChange}
                >
                  {roleList.map((val: any, key: any) => {
                    return (
                      <option key={key} value={val.role_id}>
                        {titleCase(val.role_name)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="cancel" style={{fontFamily:'Poppins-Medium'}} onClick={handleClose}>
                Cancel
              </Button>
              <Button className="submit" style={{fontFamily:'Poppins-Medium'}} onClick={updateUserRole}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Usermanagement;
