import React,{useState, useEffect} from "react";
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import fileLogo from '../Images/image 10.png';
import axios from "axios";
import { baseURL } from "./Apis";
import DataTable from "react-data-table-component";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Modal, Button } from "react-bootstrap";
import { RotatingLines } from 'react-loader-spinner';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import {decToken} from './Login';





const Uploaddoc = () => {


 
  useEffect(()=>{
    getUploadedDocs();
  },[])
  const [selectedFile, setSelectedFile]:any = useState(null);
  const [uploadedFiles, setUploadedFiles]=useState([]);
  const [modal,setModal]=useState(false);
  const [fileToDelete,setFileToDelete]=useState('');
  const [show,setShow]=useState(false);
  const handleClose=()=>{setShow(false)}
  const [pending, setPending] = useState(true);
  const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
  const [dModal, setDModal]=useState(false);
  const [uploadError,setUploadError]=useState(false);
  const authToken =decToken;
    const [fileattached, setFileattached]= useState(true);
    const dropzoneOptions: DropzoneOptions = {
        accept: {
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/pdf': ['.pdf']
        },
        maxFiles:1
      };
    var {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
      } = useDropzone(dropzoneOptions)

    const removeFile=()=>{
        acceptedFiles=[];
        console.log(acceptedFiles)
    }
      const acceptedFileItems = acceptedFiles.map((file:any,index:any) => (<>
                <span style={{marginLeft:"10px",marginRight:"10px"}}>{file.path} - {file.size} bytes</span><i className="fa fa-times" style={{color:"red"}} onClick={removeFile} aria-hidden="true"></i>
      </>
      ));



  const onClickUpload = async()=>{
    if(acceptedFiles.length>0){
    setUploadError(false);
    setModal(false);
    setPending(true);
    const File:any = acceptedFiles[0];
    if(File){
      const formData = new FormData();
      formData.append('file', File);
    try {
      const response = await fetch(baseURL+'blob/upload', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
          fileName:File.name
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPending(false);
        await getUploadedDocs();
        setDigContent(<span ><i className="fa fa-check-circle fa-lg mx-2" style={{ color: "#00A978" }}></i>Document uploaded successfully.</span>)
        setDModal(true);
        acceptedFiles=[];
      } else {
        console.error('File upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    } 
  }
}
else{
setUploadError(true);
}
  }

  const downloadDocument = (row:any)=>{
    const fileUrl = row.Url;
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = row.Name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const getUploadedDocs = async()=>{
    var link = baseURL+ "blob"
    await axios
    .get(link, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(async (response:any) => {

      setUploadedFiles(response.data.Blobs)
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
    setPending(false);
  }

  const deleteFile = async()=>{
    setPending(true);
    var link = baseURL+ "blob/"+fileToDelete
    await axios
    .delete(link, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then(async (response:any) => {

      setShow(false)
      await getUploadedDocs();
      setPending(false);
    })
    .catch((error) => {
      setPending(false);
      console.error("An error occurred:", error);
    });
  }

  const columnDefs = [
    { name: "Document Name", cell: (row: any) => (
      <div style={{color:'#0072B2', cursor:'pointer', fontFamily:'Poppins-Medium'}} onClick={(e:any)=>{downloadDocument(row)}}> {row.Name} </div>
      ) },
      {
        name:'File Format', cell:(row:any)=>
          (
            row.Name.split('.').length > 1 ? `.${row.Name.split('.').pop()}` : '')
      },
    {
      name: "Action", left:true,
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         <RiDeleteBin6Fill style={{color:'#D75977', cursor:'pointer'}} onClick={()=>{setShow(true);setFileToDelete(row.Name)}} /> 
        </div>
      ),
    },
  ];

  return (
    <>
    {pending?<div id="loader-container">
            <div id="loader">
              <RotatingLines
                strokeColor="#0072B2"
                strokeWidth="5"
                animationDuration="0.75"
                width="100"
                visible={true}
              />
            </div>
          </div>:
          <>
      <div className="titlename">Lease Document List</div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          type="button"
          className="managementbutton btn btn-block rounded-pill"
          onClick={() => setModal(true)}
        >
          Upload Lease Document
        </button>
      </div>
      <div style={{marginTop:'50px'}}>
    

<DataTable
        columns={columnDefs}
        data={uploadedFiles}
        pagination
        fixedHeader
        responsive
        fixedHeaderScrollHeight="700px"
        className="uploadDocTable"
      />
</div>
<Modal
        className="policyModal modal-xl"
        centered
        show={modal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body  >
        <section className="container" style={{width:"50%"}}>
    <div className='row'>
<div className="col-md-6 form-group" style={{ marginBottom: "10px", display:"flex", alignItems:"end", textAlign:"start" }}>
<label style={{ textAlign: "start" }}>Select file<span style={{ color: "#FE8485" }}>*</span></label>
</div>
<div className="col-md-6 form-group" style={{ marginBottom: "10px", textAlign:"end" }}>
<label style={{ textAlign: "start", fontStyle:'italic', color:'#5C5C5C' }}>Supported formats: </label><label>&nbsp;.pdf</label>
</div>
</div>

  <div {...getRootProps({ className: 'dropzone bulkcontainer' })}>
    <input {...getInputProps()} />
    <p><i className="fa fa-upload fa-3x" style={{color:"#0072B2"}} aria-hidden="true"></i></p>
    <label style={{color:"#25282B", fontFamily:"Poppins-Medium"}}>Drag & drop here</label>
    <label style={{color:"#00000080"}}>or</label>
    <label style={{color:"#0072B2", fontFamily:"Poppins-Medium",cursor:"pointer"}}>Browse File</label>
    {acceptedFiles.length>0?
    <div className='attachedFile'>
    <img src={fileLogo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" />{acceptedFileItems}
    </div>:null}
    
  </div>

  <div style={{marginTop:'20px',display:'flex', justifyContent:'center'}}>
  <button type="button" style={{ backgroundColor: '#CCE3F0', color: "#0072B2", fontFamily: "Poppins-Medium" ,fontSize:"16px", border:"1px solid #0072B2"}} 
  className="btn btn-block rounded-pill mx-2" onClick={(e:any)=>{setModal(false); acceptedFiles=[]; setUploadError(false)}} >
                Cancel
              </button>
                
                <button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} className="btn btn-block rounded-pill mx-2" onClick={onClickUpload}>
                  Upload File
                </button>
  </div>


  {uploadError && acceptedFiles.length==0?

<div style={{marginTop:'20px',display:'flex', justifyContent:'center'}}>
<label style={{ textAlign: "start", color:"#FE8485" }}>Please import .csv or .xlsx file.</label>
</div>
:null}
</section>
        </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose} centered style={{fontFamily:'Poppins-Medium',fontSize:'14px'}}>
        <Modal.Body>
        Are you sure you want to delete file? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteFile}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Dialog
        open={dModal}
        onClose={() => setDModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          onClick: () => { }, // Disable backdrop click
        }}
        PaperProps={{
          style: {
            color:'#000000',
            backgroundColor:'#ffffff',
            textAlign: 'center',
            justifyContent:'center',
            position: 'absolute',
            top: '10%', // Adjust the distance from the top as needed
            },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {digContent}
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      <Snackbar
  anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
  open={dModal}
  autoHideDuration={5000}
  onClose={() => setDModal(false)}
  message={digContent}
  ContentProps={{
    className: 'notifyBar'
  }}
  key={'top' + 'center'}
  style={{marginTop:'100px'}}
/>
        </>}
    </>
  );
};

export default Uploaddoc;
