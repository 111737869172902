import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import portfoliologo from "../Images/portfoliologo.png";
import backgroundWallpaper from "../Images/backgroundwallpaper.svg";
import "../CSS/common.css";
import { RotatingLines } from "react-loader-spinner";
import * as yup from "yup";
import Select from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { baseURL } from "./Apis";
import 'react-phone-number-input/style.css'
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import { MdCancel } from "react-icons/md";
import { Modal,Button } from "react-bootstrap";
import Terms from "./Terms";

const backgroundStyle = {
  backgroundImage: `url(${backgroundWallpaper})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
};

const validationSchema = yup.object().shape({
  fullname: yup.string().required().min(3),
  mobileflag:yup.boolean().equals([true]),
  email: yup
    .string()
    .required()
    .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
  password: yup
    .string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
    ),
  organisation: yup.string().required().min(2),
});

const Register = ({ setAuthenticated }: any) => {
  const [loader, setLoader] = useState(false);
  const [email, setemail] = useState("");
  const [fullname, setfullname] = useState("");
  const [fullnameError, setfullnameError] = useState(false);
  const [mobile, setmobile] = useState("");
  const [organisation, setorganisation]: any = useState(null);
  const [organisationError, setorganisationError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [mobilemailcheckerror, setMobileEmailCheckError] = useState(false);
  const [mobileerror, setMobileEError] = useState(false);
  const [emailerror, setemailEError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [section, setSection] = useState(1);
  const [searchedText, setSearchedText] = useState("");
  const [regUserId, setRegUserId] = useState(9);
  const [value, setValue]:any = useState('');
  const [termChecked, setTermChecked]= useState(false);
  const [termCheckedError, setTermCheckedError]= useState(false);
  const handleCheckboxChange = () => {
    setTermCheckedError(termChecked)
    setTermChecked(!termChecked);
  };

  //const [selectedOption, setSelectedOption] = useState(null);
  const [showC, setShowC] = useState(false);
  const handleCloseC = () => setShowC(false);
  const handleShowC = () => setShowC(true);


  const [showTerms, setShowTerms] = useState(false);
  const handleShowTerms = () => setShowTerms(true);
  const handleCloseTerms = () => {
    setTermChecked(true);
    setShowTerms(false)
    setTermCheckedError(false)
  };
   
  

  const handleSelectChange = (selectedOption: any) => {
    setorganisation(selectedOption);
    setorganisationError(false);
  };
  const handleInputChange = (inputValue: any) => {
    setSearchedText(inputValue);
  };

  const [orgArray, setOrgArray] = useState([]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [mecheckmessage, setMECheck] = useState("");
  const navigate = useNavigate();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const handlePhoneChange = (value:any) => {
    // Update the phoneNumber state variable with the new value
    setValue(value);

      //setmobile(value);
      setMobileEError(false);
      setemailEError(false);
      setMobileEmailCheckError(false);
    
  };

  const callLogin = () => {
    navigate("/login");
  };

  const getorgdata = () => {
    var link = baseURL+ "getOrganizations";
    axios
      .get(link)
      .then((response) => {
        //console.log(response.data.data);
        setOrgArray(response.data.data);
      })
      .catch((error) => {
        //console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    getorgdata();
  }, []);

  const checkData = async () => {
    //console.log(regUserId)
    setLoader(true);
    var RegisterData = {
      fullname: fullname,
      email: email,
      mobileflag:value && isValidPhoneNumber(value) ?  true: false,
      password: password,
      organisation: organisation != null ? organisation.label : "",
    };
    //console.log(RegisterData);
    try {
      if(termChecked){
      await validationSchema.validate(RegisterData, { abortEarly: false });
      var link = baseURL + "checkEmailVerification";
      var checkUsername = await axios.post(
        link,
        JSON.stringify({ email: RegisterData.email }),
        config
      );
      //console.log(checkUsername.data.message);
      if(mobileerror==true){

      }
      else{
      if (
        checkUsername.data.message === "Email found in our records & verified"
      ) {
        setMobileEmailCheckError(true);
        setMECheck("User already exist with same email address.");
      } 
      else {
        setSection(2);
      }}
      /* setSection(2) */
      setLoader(false);
    }
    else{
      setLoader(false)
      setTermCheckedError(true)
    }
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.message === "Email not found in our records") {
          setSection(2);
          setLoader(false);
        }
        /* setSection(2) */
      }
      // Handle errors here
      else {
        setLoader(false);
        const errorMessages = error.errors.reduce((acc: any, err: any) => {
          const path = err.split(" ")[0]; // Extract the field name from the error message
          acc[path] = err;
          return acc;
        }, {});
        if (errorMessages.fullname) {
          setfullnameError(true);
        } else {
          setfullnameError(false);
        }
        if (errorMessages.email) {
          setemailEError(true);
        } else {
          setemailEError(false);
        }
        if (errorMessages.mobileflag) {
          setMobileEError(true);
        } else {
          setMobileEError(false);
        }
        if (errorMessages.password) {
          setPasswordError(true);
        } else {
          setPasswordError(false);
        }

        if (errorMessages.organisation) {
          setorganisationError(true);
        } else {
          setorganisationError(false);
        }
      }
      // You can set an error state or show an error message to the user
    }
  };

  const onSubmit = async () => {
    if(termChecked){
    setLoader(true);
    var RegisterData = {
      fullname: fullname,
      email: email,
      mobile: value,
      password: password,
      org_id: organisation.value,
      role_id: regUserId,
    };
    var link = baseURL + "RegisterUser";
    var checkUsername = await axios.post(link, RegisterData, config);
    console.log(checkUsername);
    if (checkUsername.data.message === "User data inserted successfully") {
      setLoader(false);
      handleShowC();
      
    } else if (
      checkUsername.data.message ===
      "User already exists with the same email or mobile no."
    ) {
      setMECheck("User exist with same email address or mobile number.");
    } else {
      setMECheck("Valid email address and mobile number are required.");
    }
    setMobileEmailCheckError(true);
    setLoader(false);}
    else{
      setTermCheckedError(true);
    }
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: organisationError?'3px solid #FE8485': state.isFocused ? "2px solid #0072B2" : "2px solid #0072B2",
      borderRadius: "50px",
      backgroundColor: organisationError?'#FFF1F1':state.isFocused ? "#F4FAFF" : "#F4FAFF",
      padding: "10px",
      width: "100%",
      fontFamily: "Poppins-Regular",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        border:organisationError?'3px solid #FE8485':  "2px solid #0072B2",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the options
    }),
    option: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the individual options
    }),
    placeholder: (provided: any) => ({
      ...provided,
      textAlign: "left",
      color: "rgb(85,90,93)", // Left-align the placeholder
    }),
    singleValue: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the placeholder
    }),
  };

  const addNewOrg = () => {
    //console.log(searchedText.trim().toUpperCase());
    const seaText: any = searchedText.trim().toUpperCase();
    var orgdata = {
      name: searchedText.trim().toUpperCase(),
    };
    var link = baseURL + "addOrganization";
    axios
      .post(link, orgdata)
      .then((response) => {
        setRegUserId(3);
        getorgdata();
        setorganisation(seaText);
      })
      .catch((error) => {
        //console.error("An error occurred:", error);
      });
  };


  const filteredOptions = orgArray.filter((org:any) =>
    org.name.toLowerCase().includes(searchedText.toLowerCase())
  );


  return (
    <div style={backgroundStyle}>
      {loader == true ? (
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      ) : null}

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center vh-100">
            <div className="card py-5" style={{ width: "100%" }}>
              <div className="card-body container">
                <h5 className="card-title">
                  <img
                    src={portfoliologo}
                    style={{ maxHeight: "4vh", maxWidth: "40vw" }}
                    alt="Logo"
                  />
                </h5>
                {section === 1 ? (
                  <>
                    <h3 className="my-3">Register for a 15-Days Free Trial </h3>
                    <span style={{fontFamily:'Poppins-Regular',color:'#626262', fontStyle:'italic', fontSize:'16px'}}>All fields are mandatory.</span>
                    <form>
                      <div className="offset-1 col-10 mt-2">
                        <input
                          type="text"
                          value={fullname}
                          className={`form-control rounded-pill inputStyle p-3 ${
                            fullnameError ? "errorBorder" : ""
                          }`}
                          id="fullname"
                          placeholder="Enter your name"
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow only alphabets and spaces

                            if (regex.test(input)) {
                              setfullname(input);
                              setfullnameError(false);
                            }
                          }}
                        />{" "}
                      </div>
                      {fullnameError ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please enter full name.
                          </label>
                        </div>
                      ) : null}
                      <div className="mt-3 offset-1 col-10">
                        <input
                          type="text"
                          value={email}
                          className={`form-control rounded-pill inputStyle p-3 ${
                            emailerror || mobilemailcheckerror
                              ? "errorBorder"
                              : ""
                          }`}
                          id="email"
                          placeholder="Enter your email"
                          onChange={(e) => {
                            setemail(e.target.value);
                            setemailEError(false);
                            setMobileEError(false);
                            setMobileEmailCheckError(false);
                          }}
                        />
                      </div>
                      {emailerror ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please enter valid email address.
                          </label>
                        </div>
                      ) : null}
<div className="mt-3 offset-1 col-10">
<PhoneInput

className={`form-control rounded-pill inputStyle p-3 ${
  mobileerror ? "errorBorder" : ""
}`}
id="mobile"
defaultCountry="US"
displayInitialValueAsLocalNumber={false}
      placeholder="Enter phone number"
      value={value}
      onChange={handlePhoneChange}/>
      </div>
                      {/* <div className="mt-3 offset-1 col-10">
                        <input
                          type="text"
                          value={mobile}
                          className={`form-control rounded-pill inputStyle p-3 ${
                            mobileerror ? "errorBorder" : ""
                          }`}
                          id="mobile"
                          placeholder="Enter your mobile number"
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[0-9\b]+$/; // Regular expression to allow only numbers

                            if (input === "" || regex.test(input)) {
                              setmobile(input);
                              setMobileEError(false);
                              setemailEError(false);
                              setMobileEmailCheckError(false);
                            }
                          }}
                        />{" "}
                      </div> */}
                      {mobileerror ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please enter valid mobile number.
                          </label>
                        </div>
                      ) : null}

                      <div className="mt-3 offset-1 col-10">
                        <Select
                          className={`orgselect p-3`}
                          isSearchable
                          placeholder="Search and select an organization"
                          onChange={handleSelectChange}
                          onInputChange={handleInputChange}
                          value={organisation}
                          options={
                            searchedText.length>0?
                            orgArray.length > 0
                              ? filteredOptions.map((org: any) => ({
                                  value: org.id,
                                  label: org.name,
                                }))
                              : [] :[]
                          }
                          styles={customStyles}
                          noOptionsMessage={() => (
                            searchedText.length>3?
                            <div
                              className="btn btn-block col-10 rounded-pill"
                              style={{
                                backgroundColor: "#36BF76",
                                color: "#FFF",
                              }}
                              onClick={() => addNewOrg()}
                            >
                               Add Organisation
                            </div>:null
                          )}
                        />
                      </div>
                      {organisationError ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please enter valid organization name.
                          </label>
                        </div>
                      ) : null}
                      <div className="mt-3 offset-1 col-10">
                        <div
                          className="input-container"
                          style={{ position: "relative" }}
                        >
                          <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            className={`form-control rounded-pill inputStyle p-3 ${
                              passwordError ? "errorBorder" : ""
                            }`}
                            id="password"
                            placeholder="Enter your password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordError(false);
                            }}
                            style={{ paddingRight: "2.5rem" }} // Adjust padding for the eye icon
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <i
                              className="fa fa-info-circle icon"
                              style={{ color: "#0072B2", marginRight: "10px" }}
                              data-for="password-tooltip"
                            ></i>
                            <span
                              className="tooltip-text p-3"
                              style={{ textAlign: "left" }}
                            >
                              <label>
                                Password must be at least 8 characters long.
                              </label>
                              <br />
                              <label>
                                Password should contain a combination of
                                uppercase and lowercase letters.
                              </label>
                              <br />
                              <label>
                                Password must include at least one number and
                                one special character (!, @, #, $, %, *)
                              </label>
                              <br />
                            </span>{" "}
                            {/* Add your tooltip text */}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "45px",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <i
                              className={`fa ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              style={{ color: "#0072B2", cursor: "pointer" }}
                              onClick={togglePasswordVisibility}
                            ></i>
                          </div>
                        </div>
                      </div>
                      {passwordError ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please enter valid password.
                          </label>
                        </div>
                      ) : null}

                      {mobilemailcheckerror === true ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            {mecheckmessage}
                          </label>
                        </div>
                      ) : null}


<div className="mt-3 offset-1 col-10">
      <div
        className="input-container"
        style={{ position: "relative", textAlign: "left" }} // Ensure left alignment
      >
        <div className="form-check">
          <input 
            className="form-check-input" 
            type="checkbox" 
            value="" 
            id="flexCheckDefault" 
            checked={termChecked} 
            onChange={handleCheckboxChange} 
            style={{cursor:'pointer',borderColor:'#0072B2'}}// Handle checkbox change
          />
          <label 
            // Prevent label click from toggling checkbox
          >
            I accept Portfolio One <span style={{color:'#0072B2', cursor:'pointer'}} onClick={handleShowTerms}>Terms & Conditions</span>
          </label>
        </div>
      </div>
    </div> {termCheckedError ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Please accept Terms & Conditions.
                          </label>
                        </div>
                      ) : null}

                      <button
                        type="button"
                        className="mt-3 btn btn-block col-10 rounded-pill"
                        style={{ backgroundColor: "#0072B2", color: "white" }}
                        onClick={() => checkData()}
                      >
                        Register
                      </button>
                    </form>
                  </>
                ) : null}
                {section === 2 ? (
                  <>
                    <h3 className="my-3">Verification Link</h3>
                    <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                      <label>
                        On clicking Register, the registration verification link
                        will be sent to{" "}
                        <label style={{ fontFamily: "Poppins-Bold" }}>
                          "{email}"
                        </label>
                      </label>
                    </div>
                    {mobilemailcheckerror === true ? (
                        <div className="offset-1 col-10">
                          <label
                            className=""
                            style={{
                              color: "#FE8485",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            {mecheckmessage}
                          </label>
                        </div>
                      ) : null}


                    


                    <button
                      type="button"
                      className="mt-3 btn btn-block col-10 rounded-pill"
                      style={{ backgroundColor: "#0072B2", color: "white" }}
                      onClick={() => onSubmit()}
                    >
                      Register
                    </button>
                    <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                      <label
                        style={{ color: "#0072B2", cursor: "pointer" }}
                        onClick={() => setSection(1)}
                      >
                        Back
                      </label>
                    </div>
                  </>
                ) : null}
                <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                  <label
                    style={{ color: "#0072B2", cursor: "pointer" }}
                    onClick={callLogin}
                  >
                    Already have an account? login now.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showC} onHide={handleCloseC} backdrop="static" style={{ fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
        <Modal.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontFamily: 'Poppins-Medium', fontSize: '18px' }}>
            <i className="fa fa-check-circle fa-lg mx-2" style={{ color: '#00A978' }}></i>Verification link has been sent successfully.
          </span>
          {/* Close button */}
          <MdCancel className="" style={{cursor:'pointer',fontSize:'24px'}} aria-hidden="true" onClick={()=>{setShowC(false);navigate("/home")}} />
        </Modal.Body>
      </Modal>

      <Terms closeModal={handleCloseTerms} openModal={showTerms} />
    </div>
    
  );
};

export default Register;
