import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import portfoliologo from '../Images/portfoliologo.png';
import backgroundWallpaper from '../Images/backgroundwallpaper.svg';
import '../CSS/common.css';
import { RotatingLines } from 'react-loader-spinner';
import { loginUser } from './Apis';
import moment from 'moment';
import { findUserAccessRank } from './Apis';
import { getUserRoleBasedAccess } from './Apis';
import { encryptor } from "./Encryption";

export var userRank:any;
export var subscribedFlag:any;
export var userRoleId:any;

export var decToken:any = 'test'

const backgroundStyle = {
  backgroundImage: `url(${backgroundWallpaper})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'top left',
  minHeight: '100vh',
};

const Login = ({ setAuthenticated }: any) => {
  
  const [loader, setLoader] = useState(false)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError,setLoginError]=useState(false)
  const [loginErrorMsg,setLoginErrorMsg]=useState('')
  const navigate = useNavigate();
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const redirectToforgot = () => {
    window.location.href = '../forgotpassword';
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const callRegister = () => {
    navigate('/register')
  }
  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = backgroundWallpaper;
    backgroundImage.onload = () => {
      setBackgroundLoaded(true);
    };
  }, []);
  const onSubmit = async () => {
    setLoader(true)
    if(username.trim().length>0 && password.trim().length>0){
    var loginData = {
      email: encryptor.encryptData(username,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414'),
      password: encryptor.encryptData(password,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414'),
    }
    var checkUsername:any = await loginUser(loginData);
    if (checkUsername.data.status == 200) {
      sessionStorage.setItem('username', checkUsername.data.data.fullname)
      sessionStorage.setItem('apiToken', checkUsername.data.token)
      decToken = encryptor.decryptData(checkUsername.data.token,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414')
      sessionStorage.setItem('useremail', checkUsername.data.data.email)
      sessionStorage.setItem('user_id', checkUsername.data.data.user_id)
      sessionStorage.setItem('org_id', checkUsername.data.data.org_id)
      sessionStorage.setItem('userRoleId', checkUsername.data.data.role_id)
      sessionStorage.setItem('subscribedFlag', checkUsername.data.data.subscribed)
      sessionStorage.setItem('popCount', '0');
      await getUserRoleBasedAccess(checkUsername.data.data.role_id,checkUsername.data.data.org_id);
      if(checkUsername.data.data.subscribed===false){
      const newDate = moment(checkUsername.data.data.registration_date, 'ddd, DD MMM YYYY HH:mm:ss ZZ').add(15, 'days');
      //console.log(checkUsername.data.data.registration_date,newDate)
      const today = moment();
      const daysDifference:any = newDate.diff(today, 'days')+1;
      userRank= findUserAccessRank(subscribedFlag,daysDifference,checkUsername.data.data.sq_feet,checkUsername.data.data.count_docs);
      sessionStorage.setItem('userAccessRank',userRank);
      sessionStorage.setItem('trialDays',daysDifference);
      }
      setAuthenticated(true);
      navigate('/home')
    }
    else{
      setLoginError(true)
      console.log(checkUsername)
      setLoginErrorMsg(checkUsername.data.Message)
    }
  }
  else{
    setLoginError(true)
    const htmltext ="Invalid email address or password. Please check your credentials and try again."
    setLoginErrorMsg(htmltext)
  }
  setLoader(false)
  }

  return (
    <div style={{ ...backgroundStyle, opacity: backgroundLoaded ? 1 : 0 }}>
      {loader == true ?
        <div id="loader-container">
          <div id="loader">
            <RotatingLines
              strokeColor="#0072B2"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 d-flex justify-content-center align-items-center vh-100">
            <div className="card py-5" style={{ width: '100%' }}>
              <div className="card-body container">
                <h5 className="card-title"><img src={portfoliologo} style={{ maxHeight: "4vh", maxWidth: "40vw" }} alt="Logo" /></h5>
                <h3 className="my-3">Login</h3>
                <form>
                  <div className="mb-3 offset-1 col-10">
                    <input type="text" className={`form-control rounded-pill inputStyle p-3 ${loginError?'errorBorder':''}`} id="username" placeholder="Enter your email" onChange={(e) => {setUsername(e.target.value);setLoginError(false)}} />
                  </div>
                  <div className="mb-3  offset-1 col-10">
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className={`form-control rounded-pill inputStyle p-3 ${loginError?'errorBorder':''}`}
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => {setPassword(e.target.value);setLoginError(false)}}
                      /><i
                        className='mx-2'
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '10px',
                          transform: 'translateY(-50%)',
                        }}
                        onClick={togglePasswordVisibility}
                      >{showPassword?<i className="fa fa-eye fa-lg"  style={{ color: '#0072B2', cursor: "pointer" }} aria-hidden="true"></i>:<i className="fa fa-eye-slash fa-lg"  style={{ color: '#0072B2', cursor: "pointer" }} aria-hidden="true"></i>}
                      </i>
                    </div>
                  </div>
                  {loginError===true?
                  <div className="mb-3 offset-1 col-10">
                  <label className='' style={{color: '#FE8485',fontFamily:"Poppins-Medium"}}>{loginErrorMsg}</label>
                  </div>:null}
                   <div className="d-flex justify-content-end mb-3 offset-1 col-10"> 
                            <label style={{ color: '#0072B2', cursor:"pointer"  }} onClick={redirectToforgot}>Forgot password?</label>
                        </div> 
                  <button type="button" className="btn btn-block col-10 rounded-pill" style={{ backgroundColor: '#0072B2', color: "white" }} onClick={() => onSubmit()}>Login</button>
                  <div className="d-flex justify-content-center mt-3 offset-1 col-10">
                    <label style={{ color: '#0072B2', cursor: "pointer" }} onClick={callRegister}>New user? register now.</label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login