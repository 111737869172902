import React, { useState, useEffect } from "react";
import "../CSS/common.css";
import DataTable from "react-data-table-component";
import { Modal, Button } from "react-bootstrap";
import { RiPencilFill } from "react-icons/ri";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { RotatingLines } from 'react-loader-spinner';
import { baseURL } from "./Apis";
import {decToken} from './Login';



const Rolemanagement = () => {
  const user_id:any =
  sessionStorage.getItem("user_id") === undefined
    ? 0
    : sessionStorage.getItem("user_id");
    const org_id:any =
    sessionStorage.getItem("org_id") === undefined
      ? 0
      : sessionStorage.getItem("org_id");
  const authToken =decToken;
      const userRoleId:any =
    sessionStorage.getItem("userRoleId") === undefined
      ? 0
      : sessionStorage.getItem("userRoleId");
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [roleArray, setRoleArray] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedPermissionsByID, setSelectedPermissionsByID] = useState([]);
  const [permissionArray, setPermissionArray] = useState([]);
  const [rolePermissionArray, setRolePermissionArray] = useState([]);
  const [completeArray, setCompleteArray] = useState([]);
  const [permissionError, setPermissionError] = useState(false);
  const [roleName, setRolename] = useState("");
  const [permission, setPermission] = useState("");
  const [loader, setLoader] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => {setShowModal(false); setSelectedPermissions([]);};
  const columnDefs = [
    { name: "Role Name", selector: (row: any) => row.role_name, sortable: true },
    {
      name: "Permissions",
      selector: (row: any) => { return(<div>
      {row.permissions.map((permission: string, index: number) => (
        <button className="btn btn-block rounded-pill permissionBlocks m-1 py-1" key={index}>{permission}</button>
      ))}
    </div>)},
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RiPencilFill
            className=""
            style={{
              color: "#0072B2",
              cursor: "pointer",
              height: "18px",
              width: "18px",
            }}
            aria-hidden="true"
            onClick={() => {
              setRolename(row.role_name);
              setSelectedRole(row.role_id);
              var tempArray:any = []
              row.permissions.map((val:any,key:any)=>{
                
                var id :any = getPermissionId(val)
                var data = {
                  permission_id: id,
                  permission_name:val
                }
                tempArray.push(data)
              })
              //console.log(tempArray)
              setSelectedPermissionsByID(tempArray)
              handleShow();
              //setPermission(row.permission);
            }}
          />
        </div>
      ),
    },
  ];

  //USE EFFECT
  useEffect(() => {
    getAllData();
  }, []);

  const titleCase=(str:any)=>{
    return str.toLowerCase().split(' ').map((word :any)=> word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }



const getPermissionId=(permissionName:any)=>{
  const permission:any = permissionArray.find((permission:any) => permission.permission_name === permissionName);
  return permission ? permission.permission_id : null;
}

  const getAllData = ()=>{
    setLoader(true);
    var tempArray: any = [];
    var temprolepermissionArray: any = [];
    var temppermissionArray: any = [];
    var link = baseURL + "getPermissions";
    axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setPermissionArray(response.data.data);
        temppermissionArray= response.data.data;
        setLoader(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoader(false);
      });
      setLoader(true);
    var link = baseURL + "getRoles";
    axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        var temp:any=[];
        response.data.data.map((val:any,key:any)=>{
          if(val.role_id>=+userRoleId){
            temp.push(val)
          }
        })
        setRoleArray(temp);
        //console.log(userRoleId)
        response.data.data.map((val: any, key: any) => {
          if(val.role_id>=+userRoleId){
            //console.log(val)
            tempArray.push({
            role_id: val.role_id,
            rolename: val.role_name,
            permissions: "",
          });}
        });
        //console.log(tempArray)
        setRowData(tempArray);
        setLoader(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoader(false);
      });

      setLoader(true);
      var link =baseURL + "getPermissionsByOrgId/"+org_id;
    axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(async (response) => {
        //console.log(response.data.data);
        await setRolePermissionArray(response.data.data)
        temprolepermissionArray=response.data.data
        setLoader(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoader(false);
      });
  }

  useEffect(()=>{
    const tArray: any =[];
    const combinedArray:any =[] 
      rolePermissionArray.map((rolePermission:any) => {
        const role:any = roleArray.find((role:any) => role.role_id === rolePermission.role_id);
        const rolePermissions = rolePermission.permission_ids.map((permissionId:any) => {
          const permission:any = permissionArray.find((p:any) => p.permission_id === permissionId);
          return permission ? permission.permission_name : null;
        });
      
        combinedArray.push({
          role_id: rolePermission.role_id,
          role_name: role ? role.role_name : null,
          permissions: rolePermissions
        })
      });
      setCompleteArray(combinedArray)


      const mainArray = [...combinedArray, ...roleArray].reduce((result, item) => {
        const existingItem = result.find((existing:any) => existing.role_id === item.role_id);
      
        if (existingItem) {
          existingItem.permissions = item.permissions || existingItem.permissions || [];
        } else {
          result.push({
            ...item,
            permissions: item.permissions || []
          });
        }
      
        return result;
      }, []);
      
      mainArray.sort((a:any, b:any) => a.role_id - b.role_id);
      //console.log(mainArray,'combined')
      setCompleteArray(mainArray)
  },[rolePermissionArray])



  const updatePermissionsByOrg = () => {
    setLoader(true);
    var tempselected :any=[];
    selectedPermissions.map((val:any,key:any)=>{
      tempselected.push(val)
    })
    selectedPermissionsByID.map((val:any,key:any)=>{
      tempselected.push(val.permission_id);
    })
    //console.log(tempselected);
    if(tempselected.length>0)
    {
      setPermissionError(false);
      handleClose();
    var updateData = {
      roleId:selectedRole,
      permissionID:tempselected,
      orgId:org_id
    }
    var link = baseURL + 'updateRolePermissions';
    axios.post(link, updateData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async(response) => {
       await getAllData();
       setLoader(false);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setLoader(false);
      });
    }
    else{
      setPermissionError(true);
      setLoader(false);
    }
  };



  return (
    <> {loader?
      <div id="loader-container">
        <div id="loader">
          <RotatingLines
            strokeColor="#0072B2"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      </div> :<>
      <div className="titlename">Role Management</div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        {/* <button
          type="button"
          className="managementbutton btn btn-block rounded-pill"
          onClick={() => handleShow()}
        >
          Add Role
        </button> */}
      </div>
      <DataTable
        title={<div className="managementTableTitle">Role List</div>}
        columns={columnDefs}
        data={completeArray}
        pagination
        fixedHeader
        responsive
        fixedHeaderScrollHeight="700px"
        className="managementTable"
      />

      <Modal
        className="managementmodal"
        centered
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily:'Poppins-Medium'}}>Assign Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <label className="modalbodylabel">Role</label>
            <input
              className="form-control modalbodyinput"
              value={roleName}
              disabled
            />
            <label className="modalbodylabel">Select Permissions</label>
            <Multiselect
              className="modalbodyinput"
              displayValue="permission_name"
              onKeyPressFn={() => {}}
              onRemove={(selectedList, removedItem) => {
                if (removedItem.permission_name === "read") {
                  return; // Prevent removal if the permission is "read"
                }
            
                const updatedSelectedPermissions = selectedPermissionsByID.filter(
                  (permission: any) => permission.permission_id !== removedItem.permission_id
                );
                setSelectedPermissionsByID(updatedSelectedPermissions);
              }}
              onSearch={() => {}}
              onSelect={(selectedList, selectedItem) => {
                var dataToAdd:any={
                  permission_id:selectedItem.permission_id,
                  permission_name:selectedItem.permission_name
                }
                var tempdata:any=selectedPermissionsByID;
                tempdata.push(dataToAdd);
                setSelectedPermissionsByID(tempdata);
                setPermissionError(false);
              }}
              options={permissionArray}
              selectedValues={selectedPermissionsByID}
            />
            {permissionError?<label style={{color:'#FF5656'}} className="modalbodylabel">Please select permissions.</label>:null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" style={{fontFamily:'Poppins-Medium'}} onClick={handleClose}>
            Cancel
          </Button>
          <Button className="submit" style={{fontFamily:'Poppins-Medium'}} onClick={updatePermissionsByOrg}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>}
    </>
  );
};

export default Rolemanagement;
